.LoginParent {
  width: 300px;
  height: 300px;
  justify-content:'center';
  display: 'flex'; 
  flex-wrap: 'wrap';
}
.TextField {
  color: black;
}
.MappingWhite {
  color: white
}
.HomeStyle {
  background-image: linear-gradient(rgb(74, 74, 255), rgb(160, 147, 255), ) 
}
.Home {
  width: 100%;
  height: 100%;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
.HomePage {
  margin-top:35px;
  width: 80%;
}
.Navbar {
  height: 3.25rem;
  width: 3.25rem;
  background-color: black !important;
  color: black !important;
  position: fixed !important;
  top: 23px !important;
  right: 40px !important;
  border: 2px solid rgb(51, 51, 51) !important;
  border-radius: 50% !important;
  box-shadow: 1px 1px 4px 2px rgba(212, 212, 212, 0.835) !important;
}

.Navbar:hover {
  cursor: pointer;
  box-shadow: 1px 1px 4px 2px rgba(243, 243, 255, 0.5) !important;
  background-color: rgb(41, 41, 41) !important;
}

.Navbar:active {
  background-color:rgba(124, 124, 124, 0.5) !important;
  box-shadow: 1px 1px 4px 2px rgba(212, 212, 212, 0.835) !important;
  opacity: 70%;
}

.MenuIcon {
  font-size: 3rem;
  background-color: transparent !important;
  color: rgb(149, 149, 149);
  opacity: 80%;
}

.MenuIcon:hover {
background-color: rgb(149, 149, 149);
opacity: 80%;
}

.MenuIcon:active {
  opacity: 70%;
}



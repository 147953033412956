.Slider {
  padding-top: 30px;
  position: relative;
  display: flex;
  justify-content:center;
  align-items: center;
  padding-bottom: 100px;
}

.SliderBack {
  height: 80vmin;
}

.RightArrow {
  margin-left: 40px;
  top: 175px;
  font-size: 3rem;
  cursor: pointer;
  user-select: none;
  color: rgb(77, 90, 209);
  opacity: 95%;
  border-radius: 50%;
}
.LeftArrow {
  margin-right: 40px;
  top: 175px;
  font-size: 3rem;
  cursor: pointer;
  user-select: none;
  color: rgb(77, 90, 209);
  opacity: 95%;
  border-radius: 50%;
}

.LeftArrow:hover, .RightArrow:hover, .Toggler:hover, .SideSheetButton:hover {
  opacity: 85%;
  color: rgb(52, 68, 214);
}

.LeftArrow:active , .RightArrow:active, .Toggler:active, .SideSheetButton:active {
  color: rgb(25, 91, 190);
  transform: scale(.95);
}

.Slide {
  opacity: 0;
  transition-duration: 1s ease;
}
.SlideActive {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08)
}
.PageNumber {
  width: 150px;
  font-size: 1.25rem;
  float: center;
  display: flex;
  justify-content:center;
  align-items: center;
  color: rgb(226, 226, 226);
}
.PageControlCenter {
  width: 100%;
  display: flex;
  justify-content:center;
  margin-bottom: 25px;
  background-color: transparent;
}

.Toggler {
  margin-top: 10px;
  margin-right: 5%;
  padding: 8px;
  border-radius: 50%;
  border: black;
  background-color: azure;
  font-size: 1.5rem;
  top: 175px;
  color: rgb(77, 90, 209);
  opacity: 70%;
  cursor: pointer;
  user-select: none;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .5);

}

.ToggleParent {
  display: flex;
  justify-content: space-between;
}

.SideSheetButton {
  margin-top: 10px;
  margin-left: 5%;
  padding: 8px;
  border-radius: 50%;
  border: black;
  background-color: azure;
  font-size: 1.5rem;
  top: 175px;
  color: rgb(77, 90, 209);
  opacity: 70%;
  cursor: pointer;
  user-select: none;
  box-shadow: -1px 1px 4px 1px rgba(0, 0, 0, .5);

}

.ExtraContent {
  padding-top: 20px;
  margin: auto;
  display: block;
  justify-content: center;
  width: 80%
}
.Support {
  padding-top: 20px;
  margin: auto;
  display: block;
  justify-content: center;
}
.Z {
  z-index: auto;
}